import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect } from 'react'

import {
  HERO_CAROUSEL_ANIMATION_DELAY,
  MOBILE_VIEW_BREAKPOINT,
  SCROLL_ANIMATION_DEFAULT_PROPS,
} from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import HeroImg from '../../../images/dinner-delivery-hero.jpg'
import Form from '../../form/Form'
import ScrollAnimation from '../../scroll-animation/Container'
import './hero.less'

const HeroContainer = () => {
  const isMobile = typeof window != 'undefined' && window.innerWidth < MOBILE_VIEW_BREAKPOINT
  const { t } = useTranslation()
  const { cities } = useHomepage()

  useEffect(() => {
    window.scrollTo(2, 2)
  }, [])

  return (
    <div className="delivery-hero">
      <div className="delivery-hero__image-wrapper">
        <ScrollAnimation
          {...SCROLL_ANIMATION_DEFAULT_PROPS}
          animateIn={isMobile ? 'fadeIn' : 'fadeInLeftHero'}
          delay={isMobile ? 0 : HERO_CAROUSEL_ANIMATION_DELAY}
        >
          <div
            className="delivery-hero__image"
            style={{
              background: `url(${HeroImg}) no-repeat`,
            }}
          />
        </ScrollAnimation>
      </div>
      <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} animateIn="defaultAnimation">
        <div className="delivery-hero__form">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} animateIn={isMobile ? 'fadeInLeft' : 'fadeIn'}>
            <div className="delivery-hero__introducing">{t('Introducing')}</div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} animateIn={isMobile ? 'defaultAnimation' : 'fadeIn'}>
            <div className="delivery-hero__title">{t('Free Dinner Delivery')}</div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} animateIn={isMobile ? 'defaultAnimation' : 'fadeInUp'}>
            <div className="delivery-hero__subtitle">
              {t('MealPal is 50% less expensive than other delivery providers and')}
              &nbsp;
              <span>{t('100% as delicious.')}</span>
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} animateIn={isMobile ? 'defaultAnimation' : 'fadeInLeft'}>
            <Form customClass="form--hero" position="delivery-hero" cities={cities} />
          </ScrollAnimation>
        </div>
      </ScrollAnimation>
    </div>
  )
}

export default HeroContainer
