import React, { useLayoutEffect } from 'react'
import { RouteProps } from 'react-router-dom'

import FooterForm from '../footer-form/Container'
import Meals from '../meals/Container'
import Plans from '../plans/Container'
import SEO from '../seo'
import SingleTestimonial from '../single-testimonial/Container'
import BetterDelivery from './better-delivery/BetterDelivery'
import HeroContainer from './hero/Hero'
import HowItWorks from './how-it-works/HowItWorks'
import Receipts from './receipts/Receipts'
import RestaurantLogos from './restaurant-logos/RestaurantLogos'

type Props = RouteProps<'/delivery'>

const DeliveryContainer: React.FC<Props> = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SEO title="Delivery" />
      <HeroContainer />
      <RestaurantLogos />
      <Receipts />
      <Meals isDelivery />
      <BetterDelivery />
      <Plans />
      <SingleTestimonial />
      <HowItWorks />
      <FooterForm />
    </>
  )
}

export default DeliveryContainer
