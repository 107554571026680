import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import UtmContent from '../../../models/UtmContent'
import { AnalyticsService, UrlService } from '../../../services'
import ScrollAnimation from '../../scroll-animation/Container'
import './better-delivery.less'

const BetterDelivery = () => {
  const { t } = useTranslation()
  const { activeCityName } = useHomepage()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'delivery-better-delivery' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  const onClickHandler = () => {
    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Start saving on dinner today',
    })
  }

  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="better-delivery">
        <div className="better-delivery__content">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="better-delivery__title">{t('You deserve better delivery')}</div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="better-delivery__description">
              {t('Pick up is always an option, but we’ve got FREE delivery for when you want it.')}
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <a href={url} onClick={onClickHandler} className="better-delivery__action red-cta">
              {t('Start saving on dinner today')}
            </a>
          </ScrollAnimation>
        </div>
        <div className="better-delivery__image"></div>
      </div>
    </ScrollAnimation>
  )
}

export default BetterDelivery
