import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import useHomepage from '../../../hooks/useHomepage'
import Receipt1 from '../../../images/receipt-1.png'
import Receipt2 from '../../../images/receipt-2.png'
import Receipt3 from '../../../images/receipt-3.png'
import ReceiptMp from '../../../images/receipt-mp.png'
import UtmContent from '../../../models/UtmContent'
import { AnalyticsService, UrlService } from '../../../services'
import ScrollAnimation from '../../scroll-animation/Container'
import './receipts.less'

const Receipts = () => {
  const { t } = useTranslation()
  const { activeCityName } = useHomepage()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'delivery-receipts' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  const onClickHandler = () => {
    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Support Restaurants Near You',
    })
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 99999999, min: 1000 },
      items: 3,
    },
    small: {
      breakpoint: { max: 999, min: 0 },
      items: 1,
    },
  }

  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="receipts">
        <div className="receipts__content">
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <div className="receipts__title">
              {t('Like saving money?')} <br />
              {t('Look how much you can save with MealPal')}
            </div>
          </ScrollAnimation>
          <div className="receipts__images">
            <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} delay={600}>
              <img src={ReceiptMp} className="receipts__image--mp" />
            </ScrollAnimation>
            <div className="receipts__vs">VS.</div>
            <Carousel
              className="receipts__carousel"
              arrows={false}
              renderButtonGroupOutside={true}
              responsive={responsive}
              showDots={true}
              dotListClass="receipts__carousel-dots"
            >
              <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
                <img src={Receipt1} />
              </ScrollAnimation>
              <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} delay={200}>
                <img src={Receipt2} />
              </ScrollAnimation>
              <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS} delay={400}>
                <img src={Receipt3} className="receipts__image--last" />
              </ScrollAnimation>
            </Carousel>
          </div>
          <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
            <a href={url} onClick={onClickHandler} className="single-testimonial__action red-cta">
              {t('Start saving on dinner today')}
            </a>
          </ScrollAnimation>
        </div>
        <div className="receipts__tc">
          {t(
            'MealPal cost reflects the 20 meal plan and the $9.99 delivery plan. MealPal savings require a MealPal membership. MealPal ordering is only available at select times. DoorDash, Seamless, and UberEats prices reflect what was displayed on sites in April 2021. Tip reflects the default tip suggested by each site. All are based on a delivery to the same address in Manhattan.',
          )}
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default Receipts
