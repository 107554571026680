import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'

import useHomepage from '../../hooks/useHomepage'
import HeartImage from '../../images/heart-icon.svg'
import UtmContent from '../../models/UtmContent'
import { AnalyticsService, UrlService } from '../../services'
import ScrollAnimation from '../scroll-animation/Container'
import './single-testimonial.less'

const SingleTestimonial = () => {
  const { t } = useTranslation()
  const { activeCityName, productOffering, onNyLunchPage, onSfLunchPage, animationProps } = useHomepage()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const position = onNyLunchPage
      ? 'nylunch-single-testimonial'
      : onSfLunchPage
      ? 'sflunch-single-testimonial'
      : `${productOffering}-single-testimonial`
    const utmContent = new UtmContent({ position }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [productOffering, onNyLunchPage, onSfLunchPage])

  const onClickHandler = () => {
    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Support Restaurants Near You',
    })
  }

  return (
    <ScrollAnimation {...animationProps}>
      <div className={`single-testimonial single-testimonial--${productOffering}`}>
        <div className={`single-testimonial__content single-testimonial__content--${productOffering}`}>
          <ScrollAnimation {...animationProps}>
            <div className="single-testimonial__title">
              {t('Hybrid Workers')} <img src={HeartImage} /> MealPal
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...animationProps}>
            <div className="single-testimonial__description">
              {t('Whether you work a few days in the office per week or full time.')}
              <p>{t('MealPal has the right plan for you.')}</p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation {...animationProps}>
            <a href={url} onClick={onClickHandler} className="single-testimonial__action red-cta">
              {t('See our hybrid worker plans')}
            </a>
          </ScrollAnimation>
        </div>
        <div className="single-testimonial__image"></div>
      </div>
    </ScrollAnimation>
  )
}

export default SingleTestimonial
