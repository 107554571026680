import React from 'react'

import { SCROLL_ANIMATION_DEFAULT_PROPS } from '../../../constants'
import ScrollAnimation from '../../scroll-animation/Container'
import './restaurant-logos.less'

const RestaurantLogos = () => {
  return (
    <ScrollAnimation {...SCROLL_ANIMATION_DEFAULT_PROPS}>
      <div className="restaurants-logos">
        <img className="restaurants-logos__logo-1" src="/images/delivery-restaurants/logo-shake-shack.png" />
        <img className="restaurants-logos__logo-2" src="/images/delivery-restaurants/logo-chipotle.png" />
        <img className="restaurants-logos__logo-4" src="/images/delivery-restaurants/logo-chick-fil-a.png" />
        <img className="restaurants-logos__logo-5" src="/images/delivery-restaurants/logo-babbo.png" />
        <img className="restaurants-logos__logo-6" src="/images/delivery-restaurants/logo-minetta.png" />
        <img className="restaurants-logos__logo-7" src="/images/delivery-restaurants/logo-lukes.png" />
      </div>
    </ScrollAnimation>
  )
}

export default RestaurantLogos
